body {
  background: #FAFBFB;
}

#root {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 100px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:wght@300;400&display=swap');

.card {
    width: 300px;
    height: 200px;
    margin: 0 25px 80px 25px;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-decoration: none;
    color: #23272A;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px #0000001A;
    border: 4px solid #FFFFFF;
    border-radius: 15px;
    padding: 45px 25px 15px 25px;
    transition: .3s all;
}

.card:hover{
    color: black;
    border: 4px solid #35A7AB;
}

.card .icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2C2F33;
    position: absolute;
    top: -30px;
    border-radius: 10px;
    transition: .3s all;
}

.card .icon svg {
    position: relative;
    width: 60px;
    font-size: 20px;
    color: white;
}

.card:hover .icon {
    background-color: #35A7AB;

}

.card .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.1px;
    text-align: center;
    text-transform: uppercase;
}

.card .description {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    opacity: 0.5;
}

.wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.wrap .documentation {
  width: 400px;
  height: 100px;
  margin-bottom: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: #23272A;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px #0000001A;
  border: 4px solid #FFFFFF;
  border-radius: 15px;
  transition: .3s all;
}

.wrap .documentation:hover{
  color: black;
  border: 4px solid #35A7AB;
}

.wrap .documentation .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.wrap .documentation .icon svg {
  fill: #2c2f33;
  transition: .3s all;
}

.wrap .documentation:hover .icon svg{
  fill:#35A7AB;
}

.wrap .documentation .text {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-left: 20px;
}

.wrap .services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
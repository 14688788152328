svg {
    width: 200px;
}

svg .icon-a,
svg .icon-b,
svg .icon-c,
svg .icon-d {
    opacity: .2;
    animation: pulse .8s infinite alternate;
}

svg .icon-a {
    fill: #fcb040;
    animation-delay: 0s;
}

svg .icon-b {
    fill: #f15b29;
    animation-delay: .2s;
}

svg .icon-c {
    fill: #00a79d;
    animation-delay: .4s;
}

svg .icon-d {
    fill: #4d4d4f;
    animation-delay: .6s;
}

@keyframes pulse {
    0% {
        opacity: .2;
    }

    100% {
        opacity: .6;
    }
}